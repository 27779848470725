import {
  Typography,
  Stack,
  FormHelperText,
  FormControl,
  TextField,
  MenuItem,
  Popover,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useEffect, useState } from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { Controller, FieldErrorsImpl, UseFormSetValue } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import { SelectOptionModel } from "../../models/shared/selectOption";
import { GetToLetProperties } from "../../apis/forms/tenantApplication";
import { AppCtx } from "../../store/store";

interface Props {
  control: any;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
  setValue: UseFormSetValue<TenantApplicationModel>;
}

export const TenantApplicationPreApplication: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const { setIsLoading } = useContext(AppCtx);
  const [properties, setProperties] = useState<SelectOptionModel[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<
    SelectOptionModel[]
  >([]);
  const [propertyMenuAnchor, setPropertyMenuAnchor] =
    useState<HTMLElement | null>();
  const [propertyName, setPropertyName] = useState("");

  useEffect(() => {
    async function createPropertyOptions() {
      setIsLoading(true);
      const properties = await GetToLetProperties();
      if (properties) {
        var tempOptions: SelectOptionModel[] = [];
        properties.forEach((property) => {
          let option: SelectOptionModel = {
            value: property.id,
            option: property.name,
          };
          tempOptions.push(option);
        });
        setProperties(tempOptions);
        setFilteredProperties(tempOptions);
      }
      setIsLoading(false);
    }
    createPropertyOptions();
  }, []);

  function filterProperties(event: React.ChangeEvent<HTMLInputElement>) {
    let tempPropertyName = event.target.value;
    let searchTerm = tempPropertyName.trim().toLocaleLowerCase();
    if (searchTerm !== "") {
      var tempFilteredProperties = properties.filter((x) =>
        x.option.toLocaleLowerCase().includes(searchTerm)
      );
      if (tempFilteredProperties.length > 0) {
        setFilteredProperties(tempFilteredProperties);
        setPropertyMenuAnchor(event.currentTarget);
      } else {
        setPropertyMenuAnchor(null);
        props.setValue("PropertyId", "");
      }
    } else {
      setPropertyMenuAnchor(null);
      props.setValue("PropertyId", "");
    }
    setPropertyName(tempPropertyName);
  }

  function handleSelectProperty(option: SelectOptionModel) {
    setPropertyName(option.option);
    props.setValue("PropertyId", option.value);
    setPropertyMenuAnchor(null);
  }

  return (
    <>
      <Typography variant="button">Pre-Application</Typography>
      <TextField
        label="Property"
        placeholder="Enter property name"
        fullWidth
        value={propertyName}
        onChange={filterProperties}
        onClick={(e) => setPropertyMenuAnchor(e.currentTarget)}
        error={!!errors.PropertyId}
        helperText={errors.PropertyId?.message}
      />
      <Popover
        anchorEl={propertyMenuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(propertyMenuAnchor)}
        onClose={() => {
          setPropertyMenuAnchor(null);
          setPropertyName("");
          setFilteredProperties(properties);
          props.setValue("PropertyId", "");
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        {filteredProperties.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelectProperty(option)}>
            {option.option}
          </MenuItem>
        ))}
      </Popover>
      <Controller
        control={control}
        name="ViewingDate"
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Confirm viewing date"}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.ViewingDate && (
        <FormHelperText error>{errors.ViewingDate?.message}</FormHelperText>
      )}
      <Stack direction="row" spacing={1} alignItems="center" mt={2}>
        <Typography variant="subtitle2" width={"100%"}>
          Rent agreed
        </Typography>
        <ControlledTextField
          control={control}
          name="RentAmount"
          label="Amount"
          error={errors.RentAmount}
          hideHelperText={true}
          inputProps={{
            type: "number",
          }}
        />
        <ControlledTextField
          control={control}
          name="RentFrequency"
          label="Frequency"
          error={errors.RentFrequency}
          hideHelperText={true}
          inputProps={{
            type: "text",
          }}
          select
          selectOptions={[
            {value: "PCM", option: "PCM"},
            {value: "PerQuarter", option: "Per Quarter"}
          ]}
        />
      </Stack>
      {errors.RentAmount && (
        <FormHelperText error>{errors.RentAmount.message}</FormHelperText>
      )}
      {errors.RentFrequency && (
        <FormHelperText error>{errors.RentFrequency.message}</FormHelperText>
      )}
      <ControlledTextField
        control={control}
        name="Deposit"
        label="Deposit"
        error={errors.Deposit}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 2, mb: 2 }}
      />
      <Controller
        control={control}
        name="TenancyFrom"
        render={({ field }) => (
          <FormControl fullWidth error={!!errors.TenancyFrom}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Tenancy from"}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.TenancyFrom && (
        <FormHelperText error>{errors.TenancyFrom?.message}</FormHelperText>
      )}
      <Controller
        control={control}
        name="TenancyTill"
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Tenancy till"}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />

      {errors.TenancyTill && (
        <FormHelperText error>{errors.TenancyTill?.message}</FormHelperText>
      )}
      <ControlledTextField
        control={control}
        name="AnnualIncome"
        label="Annual Income"
        error={errors.AnnualIncome}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 2, mb: 2 }}
      />
      <ControlledTextField
        control={control}
        name="CCJAdverseHistory"
        label="CCJ/ Adverse history"
        select
        selectOptions={[
          { option: "Yes", value: true },
          { option: "No", value: false },
        ]}
        error={errors.CCJAdverseHistory}
      />
      <ControlledTextField
        control={control}
        name="CCJInformation"
        label="CCJ Information"
        error={errors.CCJInformation}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 2, mb: 2 }}
        longText
      />
      <ControlledTextField
        control={control}
        label="Guarantor Required"
        name="GuarantorRequired"
        select
        selectOptions={[
          { option: "Yes", value: true },
          { option: "No", value: false },
        ]}
        error={errors.GuarantorRequired}
      />
      <ControlledTextField
        control={control}
        name="AdditionalInformation"
        label="Additional Information"
        error={errors.AdditionalInformation}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 2, mb: 4 }}
        longText
      />
    </>
  );
};
