import { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { getProperties } from "../../apis/property/properties";
import { useNavigate } from "react-router-dom";
import { AppCtx } from "../../store/store";
import { choosePropertyColor } from "../../functions/property";
import { AppColors } from "../../constants/colors";
import {
  GetPropertiesModel,
  PropertiesPagination,
} from "../../models/property/property";
import { yellow } from "@mui/material/colors";
import { SearchRounded } from "@mui/icons-material";

export const Properties = () => {
  const navigate = useNavigate();
  const {
    propertiesList,
    setPropertiesList,
    getLoggedUser,
    setIsLoading,
    propertiesPagination,
    setPropertiesPagination,
  } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  //const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  const [searchQuery, setSearchQuery] = useState<string>();

  useEffect(() => {
    if (loggedUser) {
      fetchProperties();
    }
  }, []);

  useEffect(() => {
    if (loggedUser && propertiesPagination.currentPage > 0) {
      fetchProperties();
    }
  }, [propertiesPagination.currentPage]);

  async function fetchProperties() {
    setIsLoading(true);
    const tempGetPropertiesModel = (await getProperties(
      propertiesPagination.currentPage - 1,
      loggedUser?.token!,
      searchQuery
    )) as unknown as GetPropertiesModel;
    if (tempGetPropertiesModel) {
      setPropertiesList(tempGetPropertiesModel.properties);
      let tempPropertiesPagination: PropertiesPagination = {
        ...propertiesPagination,
        totalPages: tempGetPropertiesModel.totalPages,
      };
      setPropertiesPagination(tempPropertiesPagination);
    }
    setIsLoading(false);
  }

  const handlePropertyClick = (propertyId: number) => {
    navigate(propertyId.toString());
  };

  // const openInTeams = () => {
  //   window.open("MSTeams:/l/chat/0/0?users=" + adminEmail, "_blank");
  // };
  function handlePagination(_e: any, page: number) {
    let tempPropertiesPagination: PropertiesPagination = {
      ...propertiesPagination,
      currentPage: page,
    };
    setPropertiesPagination(tempPropertiesPagination);
  }

  function handleSearchProducts(event: React.FormEvent) {
    event.preventDefault();
    fetchProperties();
  }

  return (
    <Container maxWidth="lg">
      {loggedUser && (
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={2}
        >
          <Grid item xs={12} md={5}>
            <Typography variant="h5" fontWeight="bold">
              Welcome -{" "}
              {loggedUser.isSuperAdmin ? "SUPER ADMIN" : loggedUser.name}
            </Typography>
          </Grid>
          {loggedUser.isSuperAdmin && (
            <Grid
              item
              xs={12}
              md={4}
              component={"form"}
              onSubmit={handleSearchProducts}
            >
              <TextField
                fullWidth
                variant="standard"
                placeholder="Search Property.."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton type="submit">
                        <SearchRounded />
                      </IconButton>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid container spacing={2} marginBottom={5} alignItems={"center"}>
        {propertiesList &&
          propertiesList.map((property, index) => (
            <Grid item xs={12} md={5} lg={3} key={index}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: 150,
                  backgroundColor: choosePropertyColor(property),
                  "&:hover": {
                    backgroundColor:
                      !property.isWithdrawn &&
                      !property.isCertificateExpired &&
                      !property.hasTenants
                        ? AppColors.LightBlue
                        : AppColors.DarkYellow,
                  },
                  color:
                    property.tenantCurrentBalance < 0 ? yellow[500] : "#fff",
                }}
                onClick={() => handlePropertyClick(property.id)}
                disabled={property.isWithdrawn}
              >
                <Typography variant="h6">{property.name}</Typography>
              </Button>
            </Grid>
          ))}
      </Grid>
      {loggedUser?.isSuperAdmin && (
        <Pagination
          sx={{ mb: 4 }}
          page={propertiesPagination.currentPage}
          count={propertiesPagination.totalPages}
          shape="rounded"
          onChange={handlePagination}
        />
      )}
    </Container>
  );
};
export default Properties;
