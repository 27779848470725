import { Add, FileUpload, HighlightOff, Remove } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  Checkbox,
  Avatar,
  Grid,
  Badge,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AppCtx } from "../../store/store";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectOptionModel } from "../../models/shared/selectOption";
import { ControlledTextField } from "../../components/shared/controlledTextField";
import { MaintenanceReportModel } from "../../models/reports/maintenanceReportModel";
import { MaintenanceReportSchema } from "../../schemas/reports/maintenanceReport";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MaintenanceReportTenantDetail } from "../../components/reports/tenantDetails";
import Dropzone from "react-dropzone";
import { SubmitMaintenanceReport } from "../../apis/reports/maintenance";
import { green, red } from "@mui/material/colors";

export const MaintenanceReporting = () => {
  const navigate = useNavigate();
  const { setIsLoading, getLoggedUser, propertiesList } = useContext(AppCtx);
  const user = getLoggedUser();
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<MaintenanceReportModel>({
    resolver: yupResolver(MaintenanceReportSchema),
    defaultValues: {
      Problem: "",
      Files: [],
      ConsentAgreed: false,
      AlternatePhone: "",
    },
  });
  const maintenanceFiles = watch("Files") as File[];

  const submit = async (maintenanceReportModel: MaintenanceReportModel) => {
    setIsLoading(true);
    const formdata = new FormData();
    maintenanceReportModel.Files.forEach((files) =>
      formdata.append("Files", files)
    );
    maintenanceReportModel.Files = [];
    maintenanceReportModel.PropertyId = user?.tenantPropertyId!;
    console.log(propertiesList);
    formdata.append(
      "MaintenanceReportModelString",
      JSON.stringify(maintenanceReportModel)
    );
    await SubmitMaintenanceReport(formdata, user?.token!);
    navigate("/");
    setIsLoading(false);
  };

  const problems: SelectOptionModel[] = [
    { value: "bathroomAndToilet", option: "Bathroom and Toilet" },
    { value: "kitchen", option: "Kitchen" },
    { value: "heatingAndBoiler", option: "Heating and Boiler" },
    { value: "waterAndLeaks", option: "Water and Leaks" },
    { value: "doorsGaragesAndLocks", option: "Doors Garages and Locks" },
    {
      value: "internalFloorsWallsAndCeilings",
      option: "Internal Floors Walls and ceilings",
    },
    { value: "lighting", option: "Lighting" },
    { value: "windows", option: "Windows" },
    { value: "exteriorGarden", option: "Exterior Garden" },
    { value: "laundry", option: "Laundry" },
    { value: "furniture", option: "Furnitre" },
    { value: "electricity", option: "Electricity" },
  ];

  function handleMediaSelect(files: File[]) {
    files = files.filter(
      (item) => !maintenanceFiles.some((item2) => item2.name === item.name)
    );
    let tempMedia = maintenanceFiles.concat(files);
    setValue("Files", tempMedia);
  }

  function removeSelectedFile(
    event: React.MouseEvent<HTMLButtonElement>,
    file: File
  ) {
    event.stopPropagation();
    let tempFiles = maintenanceFiles.filter((x) => x !== file);
    setValue("Files", tempFiles);
  }

  return (
    <Container maxWidth={"sm"}>
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 2 }}>
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h4" marginBottom={1}>
            Report Maintenance
          </Typography>
          <Box component={"form"} noValidate onSubmit={handleSubmit(submit)}>
            <ControlledTextField
              control={control}
              name="Problem"
              label="What is the problem?"
              select
              selectOptions={problems}
              error={errors.Problem}
            />
            <ControlledTextField
              control={control}
              name="Description"
              label="Describing the problem"
              longText
              error={errors.Description}
              style={{ mt: 2 }}
            />
            <Dropzone onDrop={handleMediaSelect}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    style: {
                      textAlign: "center",
                      border: "2px dotted #0000001f",
                      marginTop: "10px",
                      borderRadius: "5px",
                      color: "#000000de",
                      padding: "10% 5%",
                      cursor: "pointer",
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  {maintenanceFiles.length > 0 ? (
                    <Grid container spacing={3} alignItems={"center"}>
                      {maintenanceFiles.map((item, index) => (
                        <Grid item key={index} lg={3} xs={4}>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <IconButton
                                sx={{
                                  width: 20,
                                  height: 20,
                                }}
                                onClick={(e) => {
                                  removeSelectedFile(e, item);
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    bgcolor: red[500],
                                  }}
                                >
                                  <Remove />
                                </Avatar>
                              </IconButton>
                            }
                          >
                            <Avatar
                              src={URL.createObjectURL(item)}
                              sx={{
                                width: "80px",
                                height: "80px",
                                objectFit: "contain",
                              }}
                              variant="square"
                            />
                          </Badge>
                        </Grid>
                      ))}
                      <Grid item>
                        <Avatar sx={{ bgcolor: green[500] }}>
                          <Add />
                        </Avatar>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <p>Drop photos and videos here</p>
                      <IconButton color="success">
                        <FileUpload />
                      </IconButton>
                    </>
                  )}
                  {errors.Files && (
                    <FormHelperText error>
                      {errors.Files.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            </Dropzone>

            <Controller
              control={control}
              name="NoticedDate"
              render={({ field }) => (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      label={"When was the problem 1st noticed?"}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
            {errors.NoticedDate && (
              <FormHelperText error>
                {errors.NoticedDate.message}
              </FormHelperText>
            )}

            <ControlledTextField
              control={control}
              name="Cause"
              label="What may have caused the issue?"
              error={errors.Cause}
              style={{ mt: 2 }}
              longText
            />
            <MaintenanceReportTenantDetail user={user!} />
            <ControlledTextField
              control={control}
              label="Alternate Phone"
              error={errors.AlternatePhone}
              name="AlternatePhone"
            />

            <Stack direction={"row"} alignItems={"center"} mt={4}>
              <Typography variant="subtitle2">
                I agree that my contact details be shared with contractors.
              </Typography>
              <Controller
                name="ConsentAgreed"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} sx={{ ml: "auto" }} />
                )}
              />
            </Stack>
            {errors.ConsentAgreed && (
              <FormHelperText error>
                {errors.ConsentAgreed.message}
              </FormHelperText>
            )}
            <ControlledTextField
              control={control}
              error={errors.TimeSlot}
              name="TimeSlot"
              label="Suggested time slot for contractor visits"
              longText
            />
            {errors.TimeSlot && (
              <FormHelperText error>{errors.TimeSlot.message}</FormHelperText>
            )}
            <Button
              fullWidth
              variant="contained"
              disableElevation
              size="large"
              sx={{ mt: 5, mb: 2, p: 2 }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back"}>
              <IconButton color="error" onClick={() => navigate(-1)}>
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
