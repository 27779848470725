import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";
import PropertyModel from "../../models/property/property";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const SubmitTenantApplication = async (formData: FormData) => {
  return new Promise<any>((resolve) => {
    axios
      .post(BaseUrl + "api/form/submitTenantApplication", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.status === false) {
          showSnackBar("error", response.data.message);
        } else {
          showSnackBar("success", "Successfully Registered");
        }
        resolve(response.data.data);
      })
      .catch(function (error) {
        resolve(error.message);
        showSnackBar("error", error.message);
      });
  });
};

export const GetToLetProperties = async () => {
  return new Promise<PropertyModel[]>((resolve) => {
    axios
      .get(BaseUrl + "api/form/getToLetProperties")
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        resolve([]);
        showSnackBar("error", error.message);
      });
  });
};
