import { logoutUser } from "./logout";
import { showSnackBar } from "./snackbar";

export function checkSessionExpiration(errorCode: number) {
  let isExpired = false;
  if (errorCode === 401) {
    logoutUser();
    showSnackBar("error", "Your session has expired, please log again.");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    isExpired = true;
  }
  return isExpired;
}
