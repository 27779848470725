import {
  Typography,
  Stack,
  FormControl,
  FormHelperText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Box,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import { Close } from "@mui/icons-material";

interface Props {
  control: any;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
}

export const TenantApplicationHoldingDeposit: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const [showBankDetails, setShowBankDetails] = useState(false);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="button">Holding Deposit</Typography>
        <Button
          variant="contained"
          sx={{ ml: "auto" }}
          onClick={() => setShowBankDetails(true)}
        >
          Bank Details
        </Button>
      </Stack>
      <Dialog open={showBankDetails} onClose={() => setShowBankDetails(false)}>
        <DialogTitle fontWeight={"bold"}>
          <Stack direction={"row"} alignItems={"center"} mb={1}>
            <Box width={"90%"}>Holding Deposit Bank Account Details</Box>
            <IconButton
              sx={{ ml: "auto" }}
              onClick={() => setShowBankDetails(false)}
            >
              <Close />
            </IconButton>
          </Stack>

          <Divider />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Pay to: <b>Spectra Property & Services Limited Sort</b>
            <br />
            Code: <b>40 11 15</b>
            <br />
            Account number: <b>83664104</b>
            <br />
            Note: Please make reference of the 1st line of property address when
            you make the payment.
          </Typography>
        </DialogContent>
      </Dialog>
      <ControlledTextField
        control={control}
        name="AmountPaid"
        label="Amount Paid"
        error={errors.AmountPaid}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 2, mb: 2 }}
      />
      <Controller
        control={control}
        name="DateOfPayment"
        render={({ field }) => (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Date of payment"}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.DateOfPayment && (
        <FormHelperText error>{errors.DateOfPayment.message}</FormHelperText>
      )}
      <Stack direction={"row"} alignItems={"center"} mt={1}>
        <FormHelperText>
          Property is not on hold and we will not be able to send you a formal
          application link till a holding deposit is received.
        </FormHelperText>
        <Controller
          name="HoldingDepositConfirm"
          control={control}
          render={({ field }) => <Checkbox {...field} sx={{ ml: 1 }} />}
        />
      </Stack>
      {errors.HoldingDepositConfirm && (
        <FormHelperText error>
          {errors.HoldingDepositConfirm.message}
        </FormHelperText>
      )}
    </>
  );
};
