import * as yup from "yup";

export const MaintenanceReportSchema = yup.object().shape({
  Problem: yup.string().required().label("Problem"),
  Description: yup.string().required().label("Description"),
  Files: yup.array().min(1).required().label("Photos/ Videos"),
  NoticedDate: yup.date().required().label("Noticed date"),
  Cause: yup.string().required().label("Cause of the issue"),
  ConsentAgreed: yup.bool().oneOf([true], "You should agree the consent."),
  TimeSlot: yup.string().max(250).required().label("Time Slot")
});

export const ViewMaintenanceReportSchema = yup.object().shape({
  instruction: yup.string().trim().required().label("Instruction")
})
