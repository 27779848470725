import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SelectProperty } from "../../components/property/selectProperty";
import { useNavigate, useParams } from "react-router-dom";
import PropertyModel from "../../models/property/property";
import { ArrowBack, ArrowForward, HighlightOff } from "@mui/icons-material";
import { CustomListItem } from "../../components/shared/customListItem";
import { ControlledTextField } from "../../components/shared/controlledTextField";
import { useForm } from "react-hook-form";
import { AppCtx } from "../../store/store";
import {
  AgencyInstructionModel,
  ViewMaintenanceReportModel,
} from "../../models/reports/maintenanceReportModel";
import CustomListItemModel from "../../models/shared/customListItem";
import {
  GetMaintenanceReportsByPropertyId,
  SubmitAgencyInstruction,
} from "../../apis/reports/maintenance";
import { convertDate } from "../../functions/modifyText";
import { showSnackBar } from "../../functions/snackbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { ViewMaintenanceReportSchema } from "../../schemas/reports/maintenanceReport";
import { CheckIsVideoFile } from "../../functions/helper";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const ViewMaintenanceReport: React.FC = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgencyInstructionModel>({
    resolver: yupResolver(ViewMaintenanceReportSchema),
    defaultValues: {
      instruction: "",
    },
  });
  const { propertyId } = useParams();
  const { setIsLoading, propertiesList, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();
  const [maintenanceReports, setMaintenanceReports] =
    useState<ViewMaintenanceReportModel[]>();
  const [currentReport, setCurrentReport] =
    useState<ViewMaintenanceReportModel>();
  const [currentFile, setCurrentFile] = useState<string>();

  useEffect(() => {
    if (propertyId && loggedUser) {
      fetchMaintenanceReports();
    }
  }, [propertyId]);
  
  useEffect(() => {
    selectedProperty &&
      navigate(`/reports/viewMaintenanceReports/${selectedProperty.id}`);
  }, [selectedProperty]);

  async function fetchMaintenanceReports() {
    setIsLoading(true);
    await GetMaintenanceReportsByPropertyId(propertyId!, loggedUser?.token!)
      .then((response) => {
        if (response.status) {
          let tempMaintenanceReports =
            response.data as unknown as ViewMaintenanceReportModel[];
          if (tempMaintenanceReports && tempMaintenanceReports.length > 0) {
            setMaintenanceReports(tempMaintenanceReports);
            setCurrentReport(tempMaintenanceReports[0]);
            if (
              tempMaintenanceReports[0] &&
              tempMaintenanceReports[0].fileList
            ) {
              setCurrentFile(tempMaintenanceReports[0].fileList[0]);
            }
          }
        } else {
          showSnackBar("error", response.message);
          setMaintenanceReports([]);
          setCurrentReport(undefined);
          setCurrentFile(undefined);
        }
      })
      .catch((error) => showSnackBar("error", error));
    var tempSelectedProperty = propertiesList.find(
      (x) => x.id.toString() === propertyId
    )!;
    if (tempSelectedProperty) {
      setSelectedProperty(tempSelectedProperty);
    }
    setIsLoading(false);
  }

  const maintenanceReportListItems: CustomListItemModel[] = [
    { title: "Problem", value: currentReport?.problem },
    { title: "Description", value: currentReport?.description },
    { title: "Noticed Date", value: convertDate(currentReport?.noticedDate!) },
    { title: "Cause of the issue", value: currentReport?.cause },
    { title: "Alternate Phone", value: currentReport?.alternatePhone },
    { title: "Time Slot", value: currentReport?.timeSlot },
  ];

  const handleChangeFile = (goToPrevious?: boolean) => {
    var files = currentReport?.fileList;
    if (files && files.length > 0) {
      let currentFileIndex = files.indexOf(currentFile!);
      var fileToBeDisplayed = files[0];
      if (goToPrevious) {
        if (currentFileIndex !== 0) {
          fileToBeDisplayed = files[currentFileIndex - 1];
        }
      } else if (currentFileIndex < files.length) {
        fileToBeDisplayed = files[currentFileIndex + 1];
      }
      fileToBeDisplayed && setCurrentFile(fileToBeDisplayed);
    }
  };

  const handleChangeReport = (goToPrevious?: boolean) => {
    if (maintenanceReports && maintenanceReports.length > 0) {
      let currentFileIndex = maintenanceReports.indexOf(currentReport!);
      var reportToBeDisplayed = maintenanceReports[0];
      if (goToPrevious) {
        if (currentFileIndex !== 0) {
          reportToBeDisplayed = maintenanceReports[currentFileIndex - 1];
        }
      } else if (currentFileIndex < maintenanceReports.length) {
        reportToBeDisplayed = maintenanceReports[currentFileIndex + 1];
      }
      if (reportToBeDisplayed) {
        setCurrentReport(reportToBeDisplayed);
        setCurrentFile(
          reportToBeDisplayed.fileList && reportToBeDisplayed.fileList[0]
        );
      }
    }
  };

  const handleSubmitAgencyInstruction = async (
    AgencyInstructionModel: AgencyInstructionModel
  ) => {
    setIsLoading(true);
    AgencyInstructionModel.maintenanceRequestId =
      currentReport!.maintenanceRequestId!;
    AgencyInstructionModel.propertyId = currentReport!.propertyId!;
    await SubmitAgencyInstruction(AgencyInstructionModel, loggedUser?.token!);
    setIsLoading(false);
  };

  const getLargeFileUrl = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      "maintenanceRequestId",
      currentReport?.maintenanceRequestId!.toString()!
    );
    searchParams.append("fileName", currentFile!);
    return (
      BaseUrl + "api/reports/getMaintenanceLargeFile?" + searchParams.toString()
    );
  };

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <SelectProperty
            selectedPropertyId={propertyId!}
            getSelected={setSelectedProperty}
          />
          <Typography mt={2}>
            {convertDate(currentReport?.reportedDate!)}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <IconButton
              color="primary"
              size={"large"}
              onClick={() => {
                handleChangeFile(true);
              }}
              disabled={
                !currentReport?.fileList ||
                currentReport?.fileList?.length === 0 ||
                currentReport?.fileList?.indexOf(currentFile!) === 0
              }
            >
              <Tooltip title={"Previous Photo"}>
                <ArrowBack fontSize="large" />
              </Tooltip>
            </IconButton>
            {currentFile && CheckIsVideoFile(currentFile) ? (
              <CardMedia
                component="video"
                controls
                src={getLargeFileUrl()}
                autoPlay
                sx={{
                  width: "300px",
                  height: "300px",
                  borderRadius: "10px",
                  mt: 1,
                  objectFit: "cover",
                }}
              />
            ) : (
              <Avatar
                src={
                  currentFile
                    ? getLargeFileUrl()
                    : "/images/not-found.png"
                }
                variant="square"
                sx={{
                  width: "300px",
                  height: "300px",
                  margin: "auto",
                  objectFit: "contain",
                  mt: 1,
                  borderRadius: "10px",
                }}
              />
            )}
            <IconButton
              color="primary"
              size={"large"}
              onClick={() => {
                handleChangeFile();
              }}
              disabled={
                !currentReport?.fileList ||
                currentReport?.fileList?.indexOf(currentFile!) ===
                  currentReport?.fileList?.length! - 1
              }
            >
              <Tooltip title={"Next Photo"}>
                <ArrowForward fontSize="large" />
              </Tooltip>
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={2}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleChangeReport(true);
              }}
              disabled={
                maintenanceReports?.length! === 0 ||
                maintenanceReports?.indexOf(currentReport!) === 0
              }
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleChangeReport();
              }}
              disabled={
                maintenanceReports?.indexOf(currentReport!) ===
                maintenanceReports?.length! - 1
              }
            >
              Next
            </Button>
          </Stack>
          <Box m={2}>
            {maintenanceReportListItems.map((item, index) => (
              <CustomListItem key={index} index={index} item={item} />
            ))}
            <Box
              component={"form"}
              onSubmit={handleSubmit(handleSubmitAgencyInstruction)}
            >
              <ControlledTextField
                style={{ mt: 1 }}
                longText
                control={control}
                name="instruction"
                label="Instruction to Agency"
                error={errors.instruction}
              />
              <Button
                sx={{ ml: "auto", display: "block", mt: 1 }}
                type="submit"
                variant="contained"
                color="success"
              >
                Submit
              </Button>
            </Box>
            <Typography variant="subtitle2">
              Maintenance Request ID: #{currentReport?.maintenanceRequestId!}
            </Typography>
            <Box display="flex" justifyContent="center" mt={1}>
              <Tooltip title={"Exit"}>
                <IconButton
                  color="primary"
                  onClick={() => navigate("/properties/" + propertyId!)}
                >
                  <HighlightOff sx={{ fontSize: 50 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
