import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";
import { logoutUser } from "../../functions/logout";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export async function GetPropertyDocuments(propertyId: string, token: string) {
  var documents;
  await axios
    .get(`${BaseUrl}api/property/getDocuments?propertyId=${propertyId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.data.status) {
        documents = response.data.data;
      } else {
        showSnackBar("error", response.data.message);
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        logoutUser();
        showSnackBar("error", "Your session has expired, please log again.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        showSnackBar("error", error.message);
      }
    });
  return documents;
}
