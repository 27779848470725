import * as yup from "yup";

export const TenantApplicationSchema = yup.object().shape({
  Image: yup.mixed().test("Image", (file) => { return !!file }).required("Please upload a file"),
  FirstName: yup.string().required().label("First name"),
  LastName: yup.string().required().label("Last name"),
  Email: yup.string().email().required().label("Email address"),
  PhoneNumber: yup.string().required().label("Phone number"),
  PropertyId: yup
    .number()
    .required()
    .label("property")
    .typeError("Property is required"),
  ViewingDate: yup
    .date()
    .max(new Date())
    .required()
    .label("Viewing date")
    .typeError("Viewing date is required"),
  RentAmount: yup
    .number()
    .required()
    .label("Amount")
    .typeError("Amount is required"),
  RentFrequency: yup
    .string()
    .required()
    .label("Frequency")
    .typeError("Frequency is required"),
  Deposit: yup
    .number()
    .required()
    .label("Deposit")
    .typeError("Deposit is required"),
  TenancyFrom: yup
    .date()
    .min(new Date())
    .required()
    .label("Tenancy from date")
    .typeError("Tenancy from date is required"),
  TenancyTill: yup
    .date()
    .min(new Date())
    .required()
    .label("Tenancy till date")
    .typeError("Tenancy till date is required"),
  AnnualIncome: yup
    .number()
    .required()
    .label("Annual income")
    .typeError("Annual Income is required"),
  CCJAdverseHistory: yup.bool().required().label("CCJ/ Adverse history"),
  // ccjInformation
  GuarantorRequired: yup.bool().required().label("Guarantor required"),
  AdditionalInformation: yup
    .string()
    .notRequired()
    .max(100)
    .label("Addition information"),
  AmountPaid: yup
    .number()
    .required()
    .label("Amount paid")
    .typeError("Amount paid is required"),
  DateOfPayment: yup
    .date()
    .max(new Date())
    .required()
    .label("Date of payment")
    .typeError("Date of payment is required"),
  HoldingDepositConfirm: yup.bool().oneOf([true], "Please check this."),
  Confirm1: yup.bool().oneOf([true], "Please check this."),
  Confirm2: yup.bool().oneOf([true], "Please check this."),
  DisclaimerAccepted: yup
    .bool()
    .oneOf([true], "Please read disclaimer and accept them"),
});
