import { Download, HighlightOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectProperty } from "../../components/property/selectProperty";
import { AppColors } from "../../constants/colors";
import { AppCtx } from "../../store/store";
import PropertyModel from "../../models/property/property";
import { TenancyDocument } from "../../models/property/tenancyDocument";
import { GetPropertyDocuments } from "../../apis/property/getDocuments";
import { showSnackBar } from "../../functions/snackbar";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const TenancyContractDetails: React.FC = () => {
  const navigate = useNavigate();
  const { setIsLoading, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();
  const [tenancyDocs, setTenancyDocs] = useState<TenancyDocument[]>();

  useEffect(() => {
    async function getTenancyDocuments() {
      setIsLoading(true);
      let tempDocuments = (await GetPropertyDocuments(
        propertyId!,
        loggedUser?.token!
      )) as unknown as TenancyDocument[];
      setTenancyDocs(tempDocuments);
      setIsLoading(false);
    }
    if (propertyId && loggedUser) {
      getTenancyDocuments();
    }
  }, [propertyId]);

  useEffect(() => {
    selectedProperty &&
      navigate(`/properties/documents/${selectedProperty?.id}`);
  }, [selectedProperty]);

  const handleChangeProperty = (property: PropertyModel) => {
    setSelectedProperty(property);
  };

  async function downloadFile(item: TenancyDocument) {
    const a = document.createElement("a");
    a.href =
      BaseUrl + "api/property/getDocumentByFilePath?filePath=" + item.link;
    a.download = item.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    showSnackBar("success", "Downloaded " + item.fileName + " successfully");
  }

  return (
    <Container maxWidth={"sm"}>
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 3, p: 2 }}>
        {loggedUser?.isLandLord && (
          <SelectProperty
            selectedPropertyId={propertyId!}
            getSelected={handleChangeProperty}
          />
        )}
        <Card raised>
          <CardHeader
            title={
              <Typography variant="h5" fontWeight={"bold"}>
                Documents
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <List>
              {tenancyDocs?.map((item, index) => (
                <ListItemButton
                  onClick={() => downloadFile(item)}
                  sx={{ bgcolor: blue[50], mt: 1 }}
                  key={index}
                >
                  <ListItemText
                    primary={
                      <Typography variant="button" color={AppColors.DarkBlue}>
                        {item.fileName}
                      </Typography>
                    }
                  />
                  <ListItemAvatar>
                    <IconButton>
                      <Avatar>
                        <Download />
                      </Avatar>
                    </IconButton>
                  </ListItemAvatar>
                </ListItemButton>
              ))}
            </List>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="center">
          <Tooltip title={"Back to property page"}>
            <IconButton
              color="primary"
              onClick={() => navigate("/properties/" + propertyId)}
            >
              <HighlightOff sx={{ fontSize: 60 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </Container>
  );
};
