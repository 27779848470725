import { useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { SelectOptionModel } from "../../models/shared/selectOption";

export const ControlledTextField = (props: {
  name: string;
  label: string;
  control: any;
  error?: any;
  disabled?: boolean;
  inputProps?: TextFieldProps;
  style?: SxProps;
  hideHelperText?: boolean;
  longText?: boolean;
  select?: boolean;
  selectOptions?: SelectOptionModel[];
}) => {
  const [showPassword, setshowPassword] = useState(false);
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return props.inputProps?.type === "password" ? (
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
            <OutlinedInput
              {...field}
              id={props.name}
              type={showPassword ? "text" : "password"}
              error={!!props.error}
              label={props.label}
              disabled={props.disabled || false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setshowPassword(!showPassword)}
                    onMouseDown={() => setshowPassword(true)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {!!props.error && (
              <FormHelperText error>{props.error?.message}</FormHelperText>
            )}
          </FormControl>
        ) : (
          <TextField
            {...field}
            {...props.inputProps}
            fullWidth
            id={props.name}
            label={props.label}
            error={!!props.error}
            helperText={!props.hideHelperText && props.error?.message}
            disabled={props.disabled || false}
            sx={props.style}
            {...(props.longText && {
              multiline: true,
              rows: 4,
            })}
            select={props.select}
          >
            {props.selectOptions &&
              props.selectOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                >
                  {option.option}
                </MenuItem>
              ))}
          </TextField>
        );
      }}
    />
  );
};
