import { Person } from "@mui/icons-material";
import {
  Menu,
  Box,
  Typography,
  IconButton,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppCtx } from "../../store/store";
import { logoutUser } from "../../functions/logout";
import { ProfileDataModel } from "../../models/auth/profileDataModel";
import GetProfileData from "../../apis/auth/profileCard";
import { GetShortenText } from "../../functions/modifyText";

export const ProfileCard: React.FC = () => {
  const { setIsLoading, getLoggedUser } = useContext(AppCtx);
  const user = getLoggedUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileData, setProfileData] = useState<ProfileDataModel>();

  useEffect(() => {
    async function fetchProfileData() {
      let tempProfileData = await GetProfileData(user?.token!);
      tempProfileData && setProfileData(tempProfileData);
    }
    fetchProfileData();
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = async () => {
    handleClose();
    setIsLoading(true);
    await logoutUser();
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <>
      <IconButton size="medium" onClick={handleMenu} color="inherit">
        <Person fontSize="medium" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box pl={2} pr={2}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="overline">{profileData?.caption}</Typography>
            <Button onClick={logout}>Sign Out</Button>
          </Box>

          <Divider sx={{ mb: 1, mt: 1 }} />
          <Box display={"flex"} alignItems={"center"}>
            <Avatar
              src={
                profileData?.photo ?
                `data:image/jpeg;base64,${profileData.photo}` : "/images/person.png"
              }
              sx={{ width: 100, height: 100, border: "1px solid #2E3170" }}
            />
            <Box ml={2}>
              <Typography variant="subtitle2">{user?.name && GetShortenText(user?.name)}</Typography>
              <Typography variant="subtitle1">{user?.email}</Typography>
              <Typography variant="button">{profileData?.phone}</Typography>
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
