import { MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getProperties } from "../../apis/property/properties";
import { AppColors } from "../../constants/colors";
import { choosePropertyColor } from "../../functions/property";
import PropertyModel, { GetPropertiesModel } from "../../models/property/property";
import { AppCtx } from "../../store/store";

interface Props {
  selectedPropertyId: string;
  getSelected(property: PropertyModel): void;
}

export const SelectProperty: React.FC<Props> = (props) => {
  const {
    propertiesList,
    setPropertiesList,
    propertiesPagination,
    getLoggedUser,
  } = useContext(AppCtx);
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>(
    propertiesList[0]
  );
  const loggedUser = getLoggedUser();

  useEffect(() => {
    const fetchProperties = async () => {
      let result = (await getProperties(
        0,
        loggedUser?.token!
      )) as unknown as GetPropertiesModel;
      setPropertiesList(result.properties);
    };
    if (propertiesList.length === 0 && loggedUser) {
      fetchProperties();
    }
  }, []);

  useEffect(() => {
    sendSelectedProperty(props.selectedPropertyId);
  }, [propertiesList]);

  const handleSelectProperty = (event: any) => {
    var selectedEncryptedId = event.target.value;
    sendSelectedProperty(selectedEncryptedId);
  };

  function sendSelectedProperty(selectedEncryptedId: string) {
    var tempSelectedProperty = propertiesList.find(
      (x) => x.id.toString() === selectedEncryptedId
    );
    if (tempSelectedProperty) {
      setSelectedProperty(tempSelectedProperty);
      props.getSelected(tempSelectedProperty);
    }
  }

  return (
    <Select
      value={
        selectedProperty && propertiesList.length > 0 ? selectedProperty.id : ""
      }
      onChange={handleSelectProperty}
      fullWidth
      sx={{
        backgroundColor:
          selectedProperty && choosePropertyColor(selectedProperty),
        color:
          selectedProperty && selectedProperty.tenantCurrentBalance < 0
            ? AppColors.DarkYellow
            : "#fff",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 18,
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      }}
    >
      {propertiesList &&
        propertiesList.map((item, index) => (
          <MenuItem
            value={item.id.toString()}
            key={index}
            sx={{
              color:
                item.tenantCurrentBalance < 0
                  ? AppColors.DarkYellow
                  : choosePropertyColor(item),
            }}
            disabled={item.isWithdrawn}
          >
            {item.name}
          </MenuItem>
        ))}
    </Select>
  );
};
