import axios from "axios";
import { checkSessionExpiration } from "../../functions/sessionExpired";
import { ProfileDataModel } from "../../models/auth/profileDataModel";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export default async function GetProfileData(token: string) {
  return new Promise<ProfileDataModel>((resolve, reject) => {
    axios
      .get(apiBaseUrl + "api/account/getProfileData", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        if (!checkSessionExpiration(error.response.status)) {
          reject(error);
        }
      });
  });
}
