import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";
import { logoutUser } from "../../functions/logout";
import { AgencyInstructionModel } from "../../models/reports/maintenanceReportModel";
import { ResponseModel } from "../../models/shared/responseModel";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const GetTenantPhoneNumber = async (token: string) => {
  return new Promise<string>((resolve) => {
    axios
      .get(BaseUrl + "api/reports/getTenantPhoneNumber", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logoutUser();
          showSnackBar("error", "Your session has expired, please log again.");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          showSnackBar("error", error.message);
        }
      });
  });
};

export const SubmitMaintenanceReport = async (
  formData: FormData,
  token: string
) => {
  return new Promise<any>((resolve) => {
    axios
      .post(BaseUrl + "api/reports/submitMaintenanceReport", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data.status === false) {
          showSnackBar("error", response.data.message);
        } else {
          showSnackBar("success", "Successfully Submitted");
        }
        resolve(response.data.data);
      })
      .catch(function (error) {
        resolve(error.message);
        showSnackBar("error", error.message);
      });
  });
};

export const GetMaintenanceReportsByPropertyId = async (
  propertyId: string,
  token: string
) => {
  return new Promise<ResponseModel>((resolve) => {
    axios
      .get(BaseUrl + "api/reports/getMaintenanceReports", {
        params: {
          propertyId: propertyId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logoutUser();
          showSnackBar("error", "Your session has expired, please log again.");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          showSnackBar("error", error.message);
        }
      });
  });
};

export const SubmitAgencyInstruction = async (
  agencyInstructionModel: AgencyInstructionModel,
  token: string
) => {
  return new Promise<any>((resolve) => {
    axios
      .post(
        BaseUrl + "api/reports/submitAgencyInstruction",
        agencyInstructionModel,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.status === false) {
          showSnackBar("error", response.data.message);
        } else {
          showSnackBar("success", "Successfully Submitted");
        }
        resolve(response.data.data);
      })
      .catch(function (error) {
        resolve(error.message);
        showSnackBar("error", error.message);
      });
  });
};

export const GetMaintenanceLargeFile = async (
  maintenaceRequestId: number,
  propertyId: string,
  fileName: string,
  token: string
) => {
  return new Promise<ResponseModel>((resolve) => {
    axios
      .get(BaseUrl + "api/reports/getMaintenanceLargeFile", {
        params: {
          maintenaceRequestId: maintenaceRequestId,
          propertyId: propertyId,
          fileName: fileName,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data);
      });
  });
};
