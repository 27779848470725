import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { green, pink } from "@mui/material/colors";
import { Mail, Phone } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GetTenantPhoneNumber } from "../../apis/reports/maintenance";
import { userModel } from "../../models/auth/user";

interface Props {
  user: userModel;
}

export const MaintenanceReportTenantDetail: React.FC<Props> = (props) => {
  const [tenantPhoneNumber, setTenantPhoneNumber] = useState<string>();

  useEffect(() => {
    async function fetchTenantPhoneNumber() {
      let tempPhoneNumber = await GetTenantPhoneNumber(props.user.token);
      tempPhoneNumber && setTenantPhoneNumber(tempPhoneNumber);
    }
    fetchTenantPhoneNumber();
  }, []);

  return (
    <Grid container alignItems={"center"} textAlign={"center"}>
      <Grid item xs={12} lg={6}>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: pink[500] }} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="button">Name</Typography>}
            secondary={
              <Typography variant="subtitle2">{props.user.name}</Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} lg={6}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Mail />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="button">Email</Typography>}
            secondary={
              <Typography variant="subtitle2">{props.user.email}</Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={12} lg={6}>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: green[500] }}>
              <Phone />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="button">Phone Number</Typography>}
            secondary={
              <Typography variant="subtitle2">{tenantPhoneNumber}</Typography>
            }
          />
        </ListItem>
      </Grid>
    </Grid>
  );
};
