import { Button, DialogContent, Grid, Typography } from "@mui/material";

export default function TenantApplicationDisclaimer(props: {
  acceptDisclaimer(value: boolean): void;
}) {
  return (
    <DialogContent>
      <Typography variant="h4">Disclaimer for Application</Typography>
      <Typography variant="body2" mt={2}>
        Your reference will be completed by Spectra or another nominated
        referencing agency that Spectra may use from time to time on its behalf.
        For the purposes of this application, the Credit Referencing Agency
        and/or Spectra are the Data controllers as defined in the Data
        Protection Act 1998 (the "Act").
      </Typography>
      <Typography mt={2} variant="body2">
        In order to complete your application, Spectra will consult with a
        number of sources to verify the information, including a licensed credit
        reference agency. As a result o the information received.
        <br />
        Spectra may hold and pass on any information supplied for your landlord,
        including the results of any Iinked verification checks done by the
        credit reference agency.
        <br /> By consulting with a credit reference agency, Spectra will share
        your information with them and the agency may record the results of this
        Search. This search may show how you conduct payments with other
        organizations. From time to time, this information may also be used for
        debt tracing and fraud prevention. You may request the name and address
        of the Cred Reference agency Spectra use and you may also apply for a
        copy of the information they provided to Spectra. <br />
        If you default on your rental payments, Spectra may record this on a
        centrally held database of defaulting tenants, and that such a default
        could affect any future applications for tenancies, credit and/or
        insurance, Spectra may use debt collection agencies or tracing agents to
        trace your whereabouts and recover any monies owed for Spectra and your
        landlord. <br />
        Information may be sent to you and your nominated referees by email.
        This information will only be sufficient enough to identify you and it
        won't be excessive; however, you should be aware that Information sent
        via electronic means can't be guaranteed to be secure.
      </Typography>
      <Typography mt={2} mb={2} variant="body2">
        The provisions of Ground 17 of the Housing Act 1996 will apply o his
        application If any Information win is applications found o be: untrue it
        may be grounds to terminate the
      </Typography>
      <Typography mt={2}>
        Consent: <br />I hereby confirm Spectra/ referencing agencies to contact
        my referees
      </Typography>
      <Grid mt={2}>
        <Button
          variant="contained"
          color="success"
          onClick={() => props.acceptDisclaimer(true)}
        >
          Agree
        </Button>
        <Button
          variant="outlined"
          color="error"
          sx={{ ml: 2 }}
          onClick={() => props.acceptDisclaimer(false)}
        >
          Disagree
        </Button>
      </Grid>
    </DialogContent>
  );
}
