import {
  Avatar,
  Typography,
  Stack,
  IconButton,
  Badge,
  Box,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { PhotoCamera } from "@mui/icons-material";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import { FieldErrorsImpl, UseFormSetValue } from "react-hook-form";

interface Props {
  control: any;
  setvalue: UseFormSetValue<TenantApplicationModel>;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
}

export const TenantApplicationTenantDetails: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const [imageUrl, setImageUrl] = useState("");

  function handleSelectImage(event: React.ChangeEvent<HTMLInputElement>) {
    let imageFile = event.target.files![0];
    let tempImageUrl = URL.createObjectURL(imageFile);
    setImageUrl(tempImageUrl);
    props.setvalue("Image", imageFile);
  }

  return (
    <>
      <Box ml={"auto"} width={"max-content"}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  onChange={handleSelectImage}
                  hidden
                  accept="image/*"
                  type="file"
                />

                <PhotoCamera />
              </IconButton>
            </Avatar>
          }
        >
          <Avatar
            src={imageUrl}
            sx={{
              width: "150px",
              height: "150px",
              objectFit: "contain",
            }}
            variant="square"
          />
        </Badge>
      </Box>
      {errors.Image && (
        <FormHelperText error>{errors.Image?.message}</FormHelperText>
      )}
      <Typography variant="button">Tenant</Typography>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <ControlledTextField
          style={{ width: "80%" }}
          name="Title"
          label="Title"
          select
          selectOptions={[
            { option: "Mr", value: "Mr" },
            { option: "Mrs", value: "Mrs" },
            { option: "Miss", value: "Miss" },
            { option: "Rev", value: "Rev" },
          ]}
          control={control}
        />
        <ControlledTextField
          control={control}
          name="FirstName"
          label="First Name"
          inputProps={{
            type: "text",
          }}
          error={errors.FirstName}
          hideHelperText={true}
        />
        <ControlledTextField
          control={control}
          name="MiddleName"
          label="Middle Name"
          inputProps={{
            type: "text",
          }}
          hideHelperText={true}
        />
        <ControlledTextField
          control={control}
          name="LastName"
          label="Last Name"
          error={errors.LastName}
          inputProps={{
            type: "text",
          }}
          hideHelperText={true}
        />
      </Stack>
      <FormHelperText error>{errors.FirstName?.message}</FormHelperText>
      <FormHelperText error>{errors.LastName?.message}</FormHelperText>
      <ControlledTextField
        control={control}
        name="Email"
        label="Email"
        error={errors.Email}
        inputProps={{
          type: "email",
        }}
        style={{ mt: 1 }}
      />
      <ControlledTextField
        control={control}
        name="PhoneNumber"
        label="Phone Number"
        error={errors.PhoneNumber}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 2, mb: 4 }}
      />
    </>
  );
};
